

export default (to, _from, savedPosition) => {
      if (to.hash) {
        const element = document.querySelector(to.hash);
        let scrollMargin = 0;

        if (element) {
          // Include scroll margin of element
          scrollMargin = Number(getComputedStyle(element)['scroll-margin-top']?.replace('px', ''));
        }

        return new Promise((resolve) => {
          // Don't use default offset, not calculated properly on first load
          // Css scrollMargin seems to work better
          setTimeout(() => {
            resolve({
              selector: to.hash,
              offset: { x: 0, y: scrollMargin },
              behavior: 'smooth' });
          }, 0);
        });
      }

      if (savedPosition) {
        return savedPosition;
      }

      return { x: 0, y: 0 };
    }
