const middleware = {}

middleware['fetchArchivePageData'] = require('../src/middleware/fetchArchivePageData.ts')
middleware['fetchArchivePageData'] = middleware['fetchArchivePageData'].default || middleware['fetchArchivePageData']

middleware['fetchPageType'] = require('../src/middleware/fetchPageType.ts')
middleware['fetchPageType'] = middleware['fetchPageType'].default || middleware['fetchPageType']

middleware['fetchSingleArticlePageData'] = require('../src/middleware/fetchSingleArticlePageData.ts')
middleware['fetchSingleArticlePageData'] = middleware['fetchSingleArticlePageData'].default || middleware['fetchSingleArticlePageData']

middleware['removeAbTitles'] = require('../src/middleware/removeAbTitles.ts')
middleware['removeAbTitles'] = middleware['removeAbTitles'].default || middleware['removeAbTitles']

export default middleware
